// Module UI
export const UI_SET_ENV = 'UI_SET_ENV'
export const UI_SET_DEVICE = 'UI_SET_DEVICE'
export const UI_SET_MENU = 'UI_SET_MENU'
export const UI_SET_PAGE = 'UI_SET_PAGE'
export const UI_SET_COLUMN = 'UI_SET_COLUMN'
export const UI_SET_CONTENT = 'UI_SET_CONTENT'
export const UI_SET_MONENTREPRISE_SUBCONTENT = 'UI_SET_MONENTREPRISE_SUBCONTENT'
export const UI_SET_ALLFAVORIS_SUBCONTENT = 'UI_SET_ALLFAVORIS_SUBCONTENT'
export const UI_SET_MODAL = 'UI_SET_MODAL'
export const UI_SET_MODAL_CONTENT = 'UI_SET_MODAL_CONTENT'
export const UI_SET_SHOW_FILTERS = 'UI_SET_SHOW_FILTERS'
export const UI_SET_SHOW_FAVORIS_MENU = 'UI_SET_SHOW_FAVORIS_MENU'
export const UI_SET_FILTER_INDEX = 'UI_SET_FILTER_INDEX'
export const UI_SWITCH_MAP = 'UI_SWITCH_MAP'
export const UI_SET_PLAGE = 'UI_SET_PLAGE'
export const UI_SET_GET = 'UI_SET_GET'
export const UI_SET_FAMILLES = 'UI_SET_FAMILLES'
export const UI_SET_SECTEURS = 'UI_SET_SECTEURS'
export const UI_SET_NACE_IDS = 'UI_SET_NACE_IDS'
export const UI_SET_TAGS = 'UI_SET_TAGS'
export const UI_SET_COMPETENCES = 'UI_SET_COMPETENCES'
export const UI_SET_MARCHES = 'UI_SET_MARCHES'
export const UI_SET_LAYERS = 'UI_SET_LAYERS'
export const UI_SET_LAYER_DATA = 'UI_SET_LAYER_DATA'
export const UI_SET_DECHETS = 'UI_SET_DECHETS'
export const UI_SET_DECHETS_LISTE = 'UI_SET_DECHETS_LISTE'
export const UI_SET_EDITION_MATIERES_FO = 'UI_SET_EDITION_MATIERES_FO'
export const UI_SET_FILTRES = 'UI_SET_FILTRES'
export const UI_SET_COMPANIES_SAVED = 'UI_SET_COMPANIES_SAVED'
export const UI_SET_SOURCING = 'UI_SET_SOURCING'
export const UI_SELECTED_COMPANIES = 'UI_SELECTED_COMPANIES'
export const UI_SET_DECHETS_COLUMNS = 'UI_SET_DECHETS_COLUMNS'
export const UI_SET_UI_CUSTOM_FILTRERS = 'UI_SET_UI_CUSTOM_FILTRERS'
export const UI_SET_UI_VARIABLES_FILTRERS = 'UI_SET_UI_VARIABLES_FILTRERS'
export const UI_SET_UNITES = 'UI_SET_UNITES'
export const UI_SET_LAST_CONTACT_PHONED = 'UI_SET_LAST_CONTACT_PHONED'
export const UI_SET_LOADING = 'UI_SET_LOADING'
export const UI_SET_LOADING_MAP = 'UI_SET_LOADING_MAP'
export const UI_SET_LOADING_FIRST = 'UI_SET_LOADING_FIRST'
export const UI_SET_LANGUE = 'UI_SET_LANGUE'
export const UI_SWITCH_LAYER_MARKERS = 'UI_SWITCH_LAYER_MARKERS'
export const UI_SWITCH_LAYER_HEATMAP = 'UI_SWITCH_LAYER_HEATMAP'
export const UI_SWITCH_LAYER_HEATMAP_PVRMETA = 'UI_SWITCH_LAYER_HEATMAP_PVRMETA'
export const UI_SWITCH_LAYER = 'UI_SWITCH_LAYER'
export const UI_SWITCH_LAYERS_LEGENDES_OPENED = 'UI_SWITCH_LAYERS_LEGENDES_OPENED'
export const UI_SWITCH_MAP_LAYER = 'UI_SWITCH_MAP_LAYER'
export const UI_LAYER_LOADED = 'UI_LAYER_LOADED'
export const UI_LAYERS_ZOOM_ALERT = 'UI_LAYERS_ZOOM_ALERT'
export const UI_SET_EXPORT_ID = 'UI_SET_EXPORT_ID'
export const UI_SET_EXPORT_TYPE = 'UI_SET_EXPORT_TYPE'
export const UI_SET_MAP_SIZE = 'UI_SET_MAP_SIZE'
export const UI_SWITCH_FULL_SCREEN = 'UI_SWITCH_FULL_SCREEN'
export const UI_SET_UI_CAPACITES = 'UI_SET_UI_CAPACITES'
export const UI_SET_SURFACE_ID = 'UI_SET_SURFACE_ID'
export const UI_SET_MAP_ZONE_GOOGLE = 'UI_SET_MAP_ZONE_GOOGLE'
export const UI_SET_MAP_STATE = 'UI_SET_MAP_STATE'
export const UI_SET_STREETMAP_VISIBLE = 'UI_SET_STREETMAP_VISIBLE'
export const UI_SET_RECHERCHE = 'UI_SET_RECHERCHE'
export const UI_UPLOAD_IMAGE = 'UI_UPLOAD_IMAGE'
export const UI_SET_MATIERE = 'UI_SET_MATIERE'
export const UI_SET_SAVE_USER_LOADING = 'UI_SET_SAVE_USER_LOADING'
export const UI_SET_BARRE_RECHERCHE_ACTIVE = 'UI_SET_BARRE_RECHERCHE_ACTIVE'
export const UI_SET_MAP_ADRESSE = 'UI_SET_MAP_ADRESSE'
export const UI_SET_CLASS_IMPRESSION = 'UI_SET_CLASS_IMPRESSION'
export const UI_ADD_LOADING_LAYER = 'UI_ADD_LOADING_LAYER'
export const UI_REMOVE_LOADING_LAYER = 'UI_REMOVE_LOADING_LAYER'
export const UI_SET_MAP_TOOLS_DISTANCE = 'UI_SET_MAP_TOOLS_DISTANCE'
export const UI_SET_MAP_TOOLS_SURFACE = 'UI_SET_MAP_TOOLS_SURFACE'
export const UI_SET_MODALE_GAUCHE = 'UI_SET_MODALE_GAUCHE'
export const UI_SET_MAP_ITINERAIRE_RESULT = 'UI_SET_MAP_ITINERAIRE_RESULT'
export const UI_SET_MAP_ITINERAIRE_GEOJSON = 'UI_SET_MAP_ITINERAIRE_GEOJSON'
export const UI_SET_MAP_ISOLINE_RESULT = 'UI_SET_MAP_ISOLINE_RESULT'
export const UI_SET_MAP_ISOLINE_GEOJSON = 'UI_SET_MAP_ISOLINE_GEOJSON'
export const UI_SET_MAP_GET_COORDS = 'UI_SET_MAP_GET_COORDS'
export const UI_SET_MAP_COORDS_POINTED = 'UI_SET_MAP_COORDS_POINTED'
export const UI_SET_MAP_PIN_POINTED = 'UI_SET_MAP_PIN_POINTED'
export const UI_SET_MAP_CUSTOM_INFO_WINDOW = 'UI_SET_MAP_CUSTOM_INFO_WINDOW'
export const UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE = 'UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE'
export const UI_SET_MODE_AFFICHAGE = 'UI_SET_MODE_AFFICHAGE'
export const UI_LAYERS_OPACITIES = 'UI_LAYERS_OPACITIES'
export const UI_SET_SCREEN_PDF_ON = 'UI_SET_SCREEN_PDF_ON'
export const UI_SET_RECHERCHE_ID = 'UI_SET_RECHERCHE_ID'
export const UI_RECHERCHE_EDITION = 'UI_RECHERCHE_EDITION'

export const UI_SET_RECHERCHE_SUPPRIDS = 'UI_SET_RECHERCHE_SUPPRIDS'
export const UI_SET_FIRST_DATA_LOADED = 'UI_SET_FIRST_DATA_LOADED'
export const UI_SET_MENU_SOUS_MENU_TIMESTAMP_OPENED = 'UI_SET_MENU_SOUS_MENU_TIMESTAMP_OPENED'
export const UI_SET_IMAGES_IP = 'UI_SET_IMAGES_IP'
export const UI_UPDATE_ZOOMAUTO = 'UI_UPDATE_ZOOMAUTO'
export const UI_SAVELAST_ZOOMAUTO = 'UI_SAVELAST_ZOOMAUTO'
export const UI_SET_STATUSES = 'UI_SET_STATUSES'
export const UI_SET_LIST_CONTENT = 'UI_SET_LIST_CONTENT'


// Module Entreprises
export const ENTREPRISES_INIT = 'ENTREPRISES_INIT'
export const NACES_IDS = 'NACES_IDS'
export const VARIABLES = 'VARIABLES'
export const ENTREPRISES_TOTAL = 'ENTREPRISES_TOTAL'
export const ENTREPRISES_TOTAL_LISTE = 'ENTREPRISES_TOTAL_LISTE'
export const ENTREPRISES_QUANTITE = 'ENTREPRISES_QUANTITE'
export const ENTREPRISES_GRAPHS = 'ENTREPRISES_GRAPHS'
export const ENTREPRISES_GRAPHS_ASSOLEMENT = 'ENTREPRISES_GRAPHS_ASSOLEMENT'
export const ENTREPRISES_FAMILLES_NBR = 'ENTREPRISES_FAMILLES_NBR'
export const ENTREPRISES_STATS_FAMILLES = 'ENTREPRISES_STATS_FAMILLES'
export const ENTREPRISES_NEWS = 'ENTREPRISES_NEWS'
export const ENTREPRISES_PVR_META = 'ENTREPRISES_PVR_META'
export const SET_ENTREPRISES = 'SET_ENTREPRISES'
export const SET_COMPANIES_IDS = 'SET_COMPANIES_IDS'
export const SET_COMPANIES_SORTED = 'SET_COMPANIES_SORTED'
export const SET_MARKERS = 'SET_MARKERS'
export const SET_MODE_CALCUL = 'SET_MODE_CALCUL'
export const SET_MAP_GROUPED = 'SET_MAP_GROUPED'

// Module Filters
export const FILTERS_UPDATE_DECHETS_IDS = 'FILTERS_UPDATE_DECHETS_IDS'
export const FILTERS_UPDATE_SECTEURS_IDS = 'FILTERS_UPDATE_SECTEURS_IDS'
export const FILTERS_UPDATE_CODE_NACE = 'FILTERS_UPDATE_CODE_NACE'
export const FILTERS_LOADING = 'FILTERS_LOADING'
export const FILTERS_UPDATE_MAIN_FILTERS = 'FILTERS_UPDATE_MAIN_FILTERS'
export const FILTERS_UPDATE_MAIN_DECHET = 'FILTERS_UPDATE_MAIN_DECHET'
export const FILTERS_UPDATE_ALL_DECHETS_IDS = 'FILTERS_UPDATE_ALL_DECHETS_IDS'
export const FILTERS_SET_SORT = 'FILTERS_SET_SORT'
export const FILTERS_SET_PERTINANCE = 'FILTERS_SET_PERTINANCE'
export const FILTERS_SET_CONTACT = 'FILTERS_SET_CONTACT'
export const FILTERS_SET_CUSTOM_FILTERS = 'FILTERS_SET_CUSTOM_FILTERS'
export const FILTERS_SET_VARIABLES_FILTERS = 'FILTERS_SET_VARIABLES_FILTERS'
export const FILTERS_SET_MARKERS = 'FILTERS_SET_MARKERS'
export const FILTERS_SET_ABORT_TOKEN = 'FILTERS_SET_ABORT_TOKEN'
export const FILTERS_SET_ABORT_TOKEN_MAPS = 'FILTERS_SET_ABORT_TOKEN_MAPS'
export const FILTERS_SET_ABORT_TOKEN_STATS = 'FILTERS_SET_ABORT_TOKEN_STATS'
export const FILTERS_SET_ABORT_TOKEN_GRAPHS = 'FILTERS_SET_ABORT_TOKEN_GRAPHS'
export const FILTERS_SET_ABORT_TOKEN_GRAPHS_ASSOLEMENT = 'FILTERS_SET_ABORT_TOKEN_GRAPHS_ASSOLEMENT'
export const FILTERS_SET_CARACTERISTIQUES_IDS = 'FILTERS_SET_CARACTERISTIQUES_IDS'
export const FILTERS_SET_VARIABLES_CODES = 'FILTERS_SET_VARIABLES_CODES'
export const FILTERS_SET_CAPACITES_IDS = 'FILTERS_SET_CAPACITES_IDS'
export const FILTERS_SET_CAPACITES = 'FILTERS_SET_CAPACITES'
export const FILTERS_LAST_FILTERS_TYPE = 'FILTERS_LAST_FILTERS_TYPE'

export const FILTERS_DECHETS_QTY_MIN_MAX = 'FILTERS_DECHETS_QTY_MIN_MAX'
export const FILTERS_DECHETS_QTY_ACTIVE = 'FILTERS_DECHETS_QTY_ACTIVE'
export const FILTERS_DECHETS_QTY_SOUSDECHETS = 'FILTERS_DECHETS_QTY_SOUSDECHETS'
export const FILTERS_DECHETS_QTY_SLIDER = 'FILTERS_DECHETS_QTY_SLIDER'
export const FILTERS_DECHETS_QTY_INTERVAL = 'FILTERS_DECHETS_QTY_INTERVAL'

export const FILTERS_DECHET_QTY_MIN_MAX = 'FILTERS_DECHET_QTY_MIN_MAX'
export const FILTERS_DECHET_QTY_ACTIVE = 'FILTERS_DECHET_QTY_ACTIVE'
export const FILTERS_DECHET_QTY_SOUSDECHETS = 'FILTERS_DECHET_QTY_SOUSDECHETS'
export const FILTERS_DECHET_QTY_SLIDER = 'FILTERS_DECHET_QTY_SLIDER'
export const FILTERS_DECHET_QTY_INTERVAL = 'FILTERS_DECHET_QTY_INTERVAL'

export const FILTERS_VARIABLES_TOTAUX = 'FILTERS_VARIABLES_TOTAUX'
export const FILTERS_CAPACITES_TOTAUX = 'FILTERS_CAPACITES_TOTAUX'
export const FILTERS_SET_PATH_TMP = 'FILTERS_SET_PATH_TMP'
export const FILTERS_SET_FILTERS = 'FILTERS_SET_FILTERS'
export const FILTERS_SET_ZONES_SELECTED_POLYGONS = 'FILTERS_SET_ZONES_SELECTED_POLYGONS'
export const FILTERS_SET_MARCHES_IDS = 'FILTERS_SET_MARCHES_IDS'
export const FILTERS_SET_COMPETENCES_IDS = 'FILTERS_SET_COMPETENCES_IDS'
export const FILTERS_SET_INEXAPI_FILTERS = 'FILTERS_SET_INEXAPI_FILTERS'

// Company
export const UI_SET_COMPANY_HOVER                 = 'UI_SET_COMPANY_HOVER'
export const UI_SET_COMPANY_COMPLEMENTS_LOAD      = 'UI_SET_COMPANY_COMPLEMENTS_LOAD'
export const UI_SET_COMPANY_EDITION_MATIERE       = 'UI_SET_COMPANY_EDITION_MATIERE'
export const UI_SET_COMPANY_SAVE_MATIERE          = 'UI_SET_COMPANY_SAVE_MATIERE'
export const UI_SET_COMPANY_INEX_DATA             = 'UI_SET_COMPANY_INEX_DATA'
export const UI_SET_COMPANY_DECHETS_API           = 'UI_SET_COMPANY_DECHETS_API'
export const UI_SET_COMPANY_EDIT_MATIERES_MODALE  = 'UI_SET_COMPANY_EDIT_MATIERES_MODALE'
export const UI_SET_COMPANY_MODAL                 = 'UI_SET_COMPANY_MODAL'
export const UI_SET_COMPANY_EDITION_ID            = 'UI_SET_COMPANY_EDITION_ID'
export const UI_SET_COMPANY_LOADINEXCOMPANYCANCEL = 'UI_SET_COMPANY_LOADINEXCOMPANYCANCEL'

// Module User
export const USER_SET_USER = 'USER_SET_USER'
export const USER_SET_COMPANIES_CALLS = 'USER_SET_COMPANIES_CALLS'
export const USER_SET_RECHERCHES = 'USER_SET_RECHERCHES'
export const USER_SET_SURFACES = 'USER_SET_SURFACES'
export const USER_SET_FAVORIS = 'USER_SET_FAVORIS'
export const USER_SET_FAVORIS_IDS = 'USER_SET_FAVORIS_IDS'
export const USER_SET_DOWNLOADED_IDS = 'USER_SET_DOWNLOADED_IDS'
export const USER_SET_ABORT_TOKEN = 'USER_SET_ABORT_TOKEN'

// Module Nomenclatures
export const NOMENCLATURE_SET_NOMENCLATURES = 'NOMENCLATURE_SET_NOMENCLATURES'


// Module Parcelle
export const PARCELLE_FEATURE_OVER = 'PARCELLE_FEATURE_OVER'
export const PARCELLE_PARCELLE_ON_ID = 'PARCELLE_PARCELLE_ON_ID'
export const PARCELLE_PARCELLE_ON = 'PARCELLE_PARCELLE_ON'
export const PARCELLE_PARCELLE_ON_DETAILLED = 'PARCELLE_PARCELLE_ON_DETAILLED'
export const PARCELLE_AFFICHAGE = 'PARCELLE_AFFICHAGE'
export const PARCELLE_AFFICHE_DETAIL = 'PARCELLE_AFFICHE_DETAIL'
export const PARCELLE_SEARCH_ADD_LOADING = 'PARCELLE_SEARCH_ADD_LOADING'
export const PARCELLE_SEARCH_REMOVE_LOADING = 'PARCELLE_SEARCH_REMOVE_LOADING'
export const PARCELLE_ADD_LOADING = 'PARCELLE_ADD_LOADING'
export const PARCELLE_REMOVE_LOADING = 'PARCELLE_REMOVE_LOADING'
export const PARCELLE_PARCELLE_ON_ID_OLD = 'PARCELLE_PARCELLE_ON_ID_OLD'
export const PARCELLE_PARCELLES_FAVORIS = 'PARCELLE_PARCELLES_FAVORIS'
export const PARCELLE_MENU_PARCELLES_FILTRES = 'PARCELLE_MENU_PARCELLES_FILTRES'
export const PARCELLE_MENU_PARCELLES_AFFICHE = 'PARCELLE_MENU_PARCELLES_AFFICHE'
export const PARCELLE_PARCELLES_GEOJSON = 'PARCELLE_PARCELLES_GEOJSON'
export const PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN = 'PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN'
export const PARCELLE_PARCELLES_GEOJSON_OPACITY = 'PARCELLE_PARCELLES_GEOJSON_OPACITY'
export const PARCELLE_PARCELLES_AREA_LIMIT = 'PARCELLE_PARCELLES_AREA_LIMIT'
export const PARCELLE_PARCELLES_AREA_TOO_BIG = 'PARCELLE_PARCELLES_AREA_TOO_BIG'
export const PARCELLE_PARCELLES_LIST_ORDER = 'PARCELLE_PARCELLES_LIST_ORDER'
export const PARCELLE_PARCELLES_LIST_ORDER_DIRECTION = 'PARCELLE_PARCELLES_LIST_ORDER_DIRECTION'
export const PARCELLE_PARCELLES_LISTS = 'PARCELLE_PARCELLES_LISTS'
export const PARCELLE_PARCELLE_LIST = 'PARCELLE_PARCELLE_LIST'
// Module Searches
export const SEARCHES_ADDRESS = 'SEARCHES_ADDRESS'
export const SEARCHES_LAYERS = 'SEARCHES_LAYERS'
export const SEARCHES_ADD_LAYER = 'SEARCHES_ADD_LAYER'
export const SEARCHES_REMOVE_LAYER = 'SEARCHES_REMOVE_LAYER'
export const SEARCHES_PREVIEW = 'SEARCHES_PREVIEW'
export const SEARCHES_SET_GETLAYERSBYIDS_CANCELSEARCH = 'SEARCHES_SET_GETLAYERSBYIDS_CANCELSEARCH'

// Module Graph
export const GRAPH_COULEURS_DECHETS = 'GRAPH_COULEURS_DECHETS'
export const GRAPH_COULEURS_DECHETS_DETAILS = 'GRAPH_COULEURS_DECHETS_DETAILS'
